<template>
  <section>
    <b-row class="match-height p-1">
      <b-col xl="9" md="6">
        <b-card
          no-body
          class="business-card"
          v-if="questionIndex != dataQuestion.length"
        >
          <b-card-header
            class="pb-1"
            v-if="questionIndex != dataQuestion.length"
          >
            <b-card-title
              >Soal ke {{ questionIndex + 1 }} /
              {{ dataQuestion.length }}</b-card-title
            >
            <b-nav>
              <b-nav-item>
                <router-link to="/user/list_hasil_tryout">
                  <b-button variant="primary">
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    <span class="align-right"
                      ><b style="color: white"> Kembali</b></span
                    >
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-nav>
          </b-card-header>
          <div v-for="(soal, id) in dataQuestion" :key="id">
            <b-card-body v-if="id === questionIndex">
              <b-card-text class="space_good text-justify">
                {{ soal.questions.question }}
              </b-card-text>
              <p></p>
              <b>Jawaban Anda </b>
              <p></p>
              <div class="business-items" v-if="isLoading == 0">
                <div
                  style="padding-bottom: 5px"
                  v-for="choice in soal.questions.answers"
                  v-bind:key="choice.id"
                >
                  <template>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <b-form-radio
                        :name="'some-radios' + soal.questions.id"
                        :value="choice.id"
                        v-model="soal.questions.results[0].answers_id"
                      >
                       <b v-if="choice.is_correct == 1" style="color:green"> {{ choice.answer }} </b>
                       <a v-else>{{ choice.answer }}</a>
                      </b-form-radio>
                    </div>
                  </template>
                </div>
              </div>
              <div v-else class="text-center">
                <b-img
                  :src="require('@/assets/images/avatars/13-small.png')"
                  style="
                    width: 100px;
                    height: 100px;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                  "
                  alt="logo"
                ></b-img>
                <p>
                  <b>Tunggu Sebentar ... </b>
                </p>
              </div>

              <b-progress
                v-if="isLoading"
                animated
                :value="progressBar"
                :style="{ width: progressBar + '%' }"
                show-value
                :class="'progress-bar-primary'"
              />
              <p></p>

              <div v-if="questionIndex != dataQuestion.length">
                <b-row>
                  <b-col md="6" xl="4">
                    <b-button
                      variant="warning"
                      style="width: 100%"
                      v-if="questionIndex > 0"
                      @click="prev()"
                    >
                      <feather-icon icon="ArrowLeftIcon" style="color: white" />
                      <span class="align-middle"
                        ><b style="color: white"> Sebelumnya</b></span
                      >
                    </b-button>
                  </b-col>
                  <b-col md="6" xl="4"> </b-col>
                  <b-col
                    md="6"
                    xl="4"
                    v-if="questionIndex + 1 != dataQuestion.length"
                  >
                    <b-button
                      variant="success"
                      style="width: 100%"
                      @click="next()"
                    >
                      <span class="cursor-pointer"
                        ><b style="color: white">Selanjutnya </b></span
                      >
                      <feather-icon
                        icon="ArrowRightIcon"
                        style="color: white"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <p></p>

              </div>

              <b-card-title>Kata Kunci</b-card-title>
              <b-card-text class="space_good text-justify">
                {{ soal.questions.kata_kunci }}
              </b-card-text>
              <b-card-title>Pembahasan</b-card-title>
              <b-card-text class="space_good text-justify">
                {{ soal.questions.pembahasan }}
              </b-card-text>
              <b-card-title>Referensi</b-card-title>
              <b-card-text class="space_good text-justify">
                {{ soal.questions.referensi }}
              </b-card-text>
              <b-card-title>Pembahasan Video</b-card-title>
              <video
                width="100%"
                height="auto"
                controls
                controlslist="nodownload"
                :src="getUrl + 'penyimpanan/question/' + soal.questions.video"
                type="video/mp4"
              ></video>
            </b-card-body>
          </div>
        </b-card>
      </b-col>

      <b-col xl="3" md="6" v-if="questionIndex != dataQuestion.length">
        <b-card>
          <b-row cols="5" cols-sm="5" cols-md="4" cols-lg="5">
            <b-col
              style="padding-left: 3px; padding-right: 3px"
              v-for="(answer, index) in dataQuestion"
              v-bind:key="answer.id + 1000"
              class="text-center"
            >
              <b-button
                v-if="questionIndex != index && answer.correct_answer == 1"
                @click="
                  jumpquestion(index, answer.answers_id, answer.cons_answer)
                "
                variant="success"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b-button
                v-else-if="questionIndex != index && answer.correct_answer == 0"
                @click="jumpquestion(index)"
                variant="danger"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;"
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <b-button
                v-else-if="
                  questionIndex != index && answer.correct_answer == null
                "
                @click="jumpquestion(index)"
                variant="danger"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>

              &nbsp;&nbsp;&nbsp;&nbsp;
              <b-button
                v-else
                @click="
                  jumpquestion(index, answer.answers_id, answer.cons_answer)
                "
                v-bind:key="index"
                variant="info"
                class="text-center"
                style="
                  padding-left: 8px;
                  padding-right: 8px;
                  width: 45px;
                  height: 45px;
                "
              >
                {{ index + 1 }}
              </b-button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p></p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BTableSimple,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  BEmbed,
} from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    ToastificationContent,
    BImg,
    Swal,
    BCardText,
    BBadge,
    BCardHeader,
    BTableSimple,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
    BProgress,
    VueApexCharts,
    BEmbed,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id_session: localStorage.getItem("Uid"),
      id_param: this.$route.params.id,
      questionIndex: 0,
      currentQuestion: 0,
      currentAnswer: 0,
      radioGroup: 0,
      dataQuestion: [],
      datacountEmptyExam: 0,
      datacountAnswerExam: 0,
      datacountRaguExam: 0,
      dataviewAnswerExam: [],
      answer: "",
      start: 1,
      end: "",
      split: "",
      cons_answer: 0,
      isLoading: 0,
      answerIndex: 0,
      hours: "00",
      minutes: "00",
      seconds: "00",
      distance: 0,
      progressBar: 0,
      getUrl:""
    };
  },

  async mounted() {
    this.checkAuth();
    // this.renderChart(this.data, this.options, this.plugins, this.styles)
    await this.getQuestions();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getQuestions() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `questions/exam/` +
            this.id_param +
            `/` +
            this.id_session
        )
        .then((res) => {
          this.dataQuestion = res.data.data;
          // console.log("miki",this.dataQuestion)
        });
    },

    jumpquestion(number, answer, cons) {
      this.questionIndex = number;
      this.answerIndex = answer;
      this.cons_answer = cons;
      // console.log(this.questionIndex, this.answerIndex, this.cons_answer);
    },
    prev() {
      this.questionIndex--;
    },
    next() {
      this.questionIndex++;
    },
  },
};
</script>

<style>
.space_good {
  white-space: pre-wrap;
}
.column_wrapper {
  column-count: 10;
}
</style>
